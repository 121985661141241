import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO 
      title="Diced News: Not Found" 
      description='Diced News: Not Found' 
      author='Diced Food' 
      keywords={[`healthy, food, dicedfood`]} 
      lang='EN'  />
    <div style={{height: "90vh"}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage

import React from "react"
import Header from "./header"
import Footer from "./footer"
import styled, {ThemeProvider, createGlobalStyle} from 'styled-components'

const theme = {
  COFont: 'Open Sans',
  layoutPad: '20px'
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;

const Wrapper=styled.main`
  padding:${props => props.theme.layoutPad};
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <div>
    <Header />
      <Wrapper>{children}</Wrapper>
    <Footer/>
    <GlobalStyle/>
    </div>
  </ThemeProvider>
)
export default Layout

import { StaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'

const Popup = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding-top: 50px;
  padding-bottom: 50px;
  position: absolute;
  animation: navIn 0.5s;
  -webkit-animation: navIn 0.5s;
  width: 100%;
  top: 105px;
  right: 0;
  overflow: hidden;
  background-color: #323232;
  display: none;
  z-index: 1;

  .dicedLogo {
    width: 250px;
    margin: 0 auto;
  }
  .navLinka {
    text-decoration: none;
    color: white;
    font-size: 28px;
  }
  .navLink1 {
    /* width: 500px;
    margin: 0 auto; */
    margin: 20px;
    padding-left: 40px;
    /* text-align: center; */
  }

`;

const HeaderWrapper=styled.header`
  background-color: #BBD448;
  padding: 20px;
  .navGrid {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(5, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 65px;
    -ms-grid-rows: 65px;
  }
  .navLink {
    width: 50px;
    display: block;
    text-decoration: none;
    text-align: center;
    color: white;
    grid-column: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    height: 50px;
  }
  .Logo {
    height: 65px;
    width: 250px;
  }
  #burgerContainer {
    display: inline-block;
    cursor: pointer;
    grid-column: 5;
    grid-row: 1;
    -ms-grid-column: 5;
    -ms-grid-row: 1;
    height: 50px;
    margin: auto 0 auto auto;
  }
  .burgerMenu {
    width: 35px;
    height: 27px;
  }
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    box-shadow:  -1px 1px #888888;
    margin: 6px 0;
    transition: 0.4s;
  }
  /* bar1 Rotate */
  .delta .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
  }

  /* bar2 Fade out */
  .delta .bar2 {
    opacity: 0;
  }

  /* bar3 Rotate */
  .delta .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
  }
  .display {
    display: block !important;
    transition: 0.4s;
  }

  @media only screen and (max-width: 767px){
    .navLink {
      display: block;
      text-decoration: none;
      text-align: center;
      color: white;
      grid-column: 1/3;
    }
  }
  @keyframes navIn {
    0% {height:0px}
    100% {height: 182px}
  }
`;

class Header extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      delta: false,
      display: false
    }
    this.animate_Burger = this.animate_Burger.bind(this);
  }
  componentDidMount() {

  }

  animate_Burger(e){
    this.setState((state, props)=>{
      return{
        delta: state.delta ? false : true,
        display: state.display ? false : true
      }
    })
  }

  render(props){
    const delta = this.state.delta ? 'delta' : '';
    const display = this.state.display ? 'display' : '';
    return(
    <HeaderWrapper>
      <nav className="navGrid">
        <a className='navLink1' href='https://dicedfood.com'>
          <Img className='Logo' alt='diced food logo' fluid={this.props.data.dicedLogo.childImageSharp.fluid}/>
        </a>
        <div id="burgerContainer">
          <div className={`burgerMenu ${delta}`} onClick={this.animate_Burger}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </nav>
      <Popup className={`${display}`}>
        <div className='navLink1'>
          <a className='navLinka' href='https://dicedfood.com'>HOME</a>
        </div>
        <div className='navLink1'>
          <a className='navLinka' href='https://dicedfood.com#content'>ABOUT US</a>
        </div>
        <div className='navLink1'>
          <a className='navLinka' href='https://dicedfood.com#contact'>CONTACT</a>
        </div>
      </Popup>
    </HeaderWrapper>
  )}  
}

export default () => (
  <StaticQuery query = {graphql`
    query {
      dicedLogo: file(relativePath: { eq: "DICED-Logo-White-2.png" }) {
        childImageSharp {
          fluid( maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }`}
    render = {data =>
      <Header data={data}/>
    }
  />
)
import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

const FooterWrapper = styled.footer`
  padding: 20px 100px;
  margin: 0 auto;
  background-color: #323232;
  position: relative;
  height: 100%;
  .copyright {
    color: white;
    position: absolute;
    bottom: 40px;
    right: 100px;
    text-decoration: none;
  }
  .dicedLogo {
    width: 250px;
    margin-bottom: 100px;
    margin-top: 27px;
  }
  .Grid {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-columns: 1fr 1fr;
    grid-template-rows: 100%;
    -ms-grid-rows: 1fr 1fr;
  }
  .box1 {
    grid-column: 1;
    -ms-grid-column: 1;
  }
  .box2 {
    width: 100%;
    margin: auto 0;
    grid-column: 2;
    -ms-grid-column: 2;
  }

  @media only screen and (max-width: 767px) {
    .copyright {
      color: white;
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
    .dicedLogo {
      width: 200px;
      margin-bottom: 70px;
    }
    padding: 20px 20px;
  }
`;
const ContactB = styled.a`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-decoration: none;
  float: right;
  border-radius: 25px;
  background-color: #BBD448;
  color: black;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 20px;
`;

const Footer = () => (
  <StaticQuery
    query = {graphql`
      query {
        dicedLogo: file(relativePath: { eq: "DICED-Logo-White.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 250) {
              ...GatsbyImageSharpFluid_noBase64
              presentationWidth
            }
          }
        }
    }`}
    render = {data =>
      <FooterWrapper>
        <div className='Grid'>
          <div className='box1'>
            <a href='https://dicedfood.com'><Img alt='diced food logo' className='dicedLogo' fluid={data.dicedLogo.childImageSharp.fluid} /></a>
          </div>
          <div className='box2'>
            <ContactB href='https://dicedfood.com#footer'>Contact</ContactB>
            <a href='https://dicedfood.com' className='copyright'>© {new Date().getFullYear()}</a>
          </div>
        </div>
      </FooterWrapper>
   }
  />
)
export default Footer
